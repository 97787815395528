import { UpcomingBadge } from './badge/UpcomingBadge';
import { LimitedBadge } from './badge/LimitedBadge';
import { SoldOutBadge } from './badge/SoldOutBadge';
import { ClosedBadge } from './badge/ClosedBadge';
import { PeriodLimitedBadge } from './badge/PeriodLimitedBadge';
import { QuantityLimitedBadge } from './badge/QuantityLimitedBadge';
import { RealtimeBadge } from './badge/RealtimeBadge';
import { MembershipBadge } from './badge/MembershipBadge';
import { NftBadge } from './badge/NftBadge';
import { RankBadge } from './badge/RankBadge';
import { product_badge } from '../../../../features/ProductList/constant';
import { ProductBadgeBase } from './ProductBadgeBase';
import { ProductBadgeStyleHelper } from './ProductBadgeStyleHelper';

const BadgeClassMap = {
  upcoming: UpcomingBadge,
  limited: LimitedBadge,
  sold_out: SoldOutBadge,
  closed: ClosedBadge,
  period_limited: PeriodLimitedBadge,
  quantity_limited: QuantityLimitedBadge,
  realtime: RealtimeBadge,
  membership: MembershipBadge,
  nft: NftBadge,
  ranking: RankBadge,
} as const;

type BadgeClassTypeMap = {
  [K in keyof typeof BadgeClassMap]: InstanceType<(typeof BadgeClassMap)[K]>;
};

type BadgeConstructorTypeMap = {
  [K in keyof typeof BadgeClassMap]: ConstructorParameters<(typeof BadgeClassMap)[K]>[0];
};

export class ProductBadgeFactory {
  static readonly type = product_badge;

  static create<Type extends keyof BadgeClassTypeMap>(
    type: Type,
    props: BadgeConstructorTypeMap[Type],
  ): BadgeClassTypeMap[Type] {
    const BadgeClass = BadgeClassMap[type];
    const BadgeStyler = new ProductBadgeStyleHelper(type, props);

    if (!BadgeClass) {
      throw new Error(`지원되지 않는 뱃지 타입: ${type}`);
    }

    // TODO: FIX type
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return new BadgeClass(props, BadgeStyler);
  }

  static isInstanceOf<Type extends keyof BadgeClassTypeMap>(badge: ProductBadgeBase, type: Type): boolean {
    const ExpectedClass = BadgeClassMap[type];
    return badge instanceof ExpectedClass;
  }
}

