import { ProductBadgeBase } from '../ProductBadgeBase';
import { IProductBadge } from '../interface';
import type { Html } from 'rune-ts';
import { LaunchIcon } from '../../../atoms/Icon';

export class UpcomingBadge extends ProductBadgeBase<Date> implements IProductBadge {
  setLabel(): void {
    const diff = ProductBadgeBase.diffDate(this.data.value, new Date());

    // 4일 이상이면 숨겨진다.
    if (diff.day >= 4 || diff.second < 0) {
      this.label = ET('mps2::product::badge::upcoming');
    } else if (diff.day > 0) {
      this.label = ET('mps2::product::badge::period_limited_day', diff);
    } else if (diff.hour > 0) {
      this.label = ET('mps2::product::badge::period_limited_hour', diff);
    } else if (diff.minute > 0) {
      this.label = ET('mps2::product::badge::period_limited_minute', diff);
    } else {
      this.label = ET('mps2::product::badge::period_limited_second', diff);
    }
  }

  isTimerNeeded(): boolean {
    return true;
  }

  getIcon(): Html | string | null {
    return LaunchIcon;
  }
}
