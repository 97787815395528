import { CustomEventWithoutDetail, html, View } from 'rune-ts';
import klass from './ProductTag.module.scss';
import { UtilS } from '../../../../../../modules/Util/S/Function/module/UtilS';
import { padWithZero } from '../../../../../../modules/Util/S/Function/util';
import { ProductCardData, ProductTagData, ProductTagType } from '../../../../features/ProductList/type';
import { product_tag } from '../../../../features/ProductList/constant';
import { differenceInSeconds } from 'date-fns';
import { ProductCardHelper } from '../ProductCard/ProductCardHelper';
import { typoPcMo } from '../../../../shared/typography/typo';

interface Option {
  klass?: string;
  is_mobile: boolean;
}

export class ProductTagExpiredEvent extends CustomEventWithoutDetail {}

export class ProductTag extends View<ProductTagData> {
  tag: {
    type: ProductTagType;
    label: string;
  } | null = null;

  timer: any | null = null;
  diff?: { day: number; hour: number; minute: number; second: number };

  static Type = product_tag;

  constructor(
    data: ProductTagData,
    private option: Option,
  ) {
    super(data, option);
    this.setTag();
  }

  override template() {
    if (!this.tag) {
      return html`<span></span>`;
    }

    return html`<span
      class="${this.option.klass ?? ''} ${klass[this.tag.type]} ${klass.product_tag} ${typoPcMo({
        is_mobile: this.option.is_mobile,
        pc: '14_medium',
        mo: '12_regular',
      })}"
    >
      ${this.tag.label}
    </span>`;
  }

  protected override onRender() {
    if (!this.isTimerNeeded()) {
      return;
    }

    this.timer = setInterval(() => {
      this.setTag();

      if (this.tag) {
        this.redrawRemainingTime();
      } else {
        this.dispatchEvent(ProductTagExpiredEvent, {
          bubbles: true,
        });
        clearInterval(this.timer);
        this.timer = null;
      }
    }, 1000);
  }

  private redrawRemainingTime() {
    if (!this.isTimerNeeded()) {
      return;
    }

    const el = this.element();
    if (!el) {
      return;
    }

    if (this.tag && this.tag.label) {
      el.innerText = this.tag.label;
    } else {
      el.innerText = '';
    }
  }

  protected override onUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  }

  private isValid(type: string) {
    return Object.keys(product_tag).includes(type);
  }

  private isTimerNeeded(): boolean {
    return this.data.type === ProductTag.Type.period_limited || this.data.type === ProductTag.Type.upcoming;
  }

  private setTag() {
    const { type } = this.data;
    if (!this.isValid(type)) {
      this.tag = null;
      return;
    }

    if (type === product_tag.sold_out) {
      this.tag = { type, label: ET('mps2::product::tag::sold_out') };
      return;
    }

    if (type === product_tag.upcoming) {
      const diff_label = this.getUpcomingLabel(this.data.value);
      if (diff_label) {
        this.tag = { type, label: diff_label };
      } else {
        this.tag = null;
      }
      return;
    }

    if (type === product_tag.quantity_limited) {
      const count = this.data.value;
      if (count > 0 && count < 100) {
        this.tag = {
          type,
          label: ET('mps2::product::tag::quantity_limited', { count: this.data.value }),
        };
      }
    }

    if (type === product_tag.period_limited) {
      const diff_label = this.getDiffTimeLabel(this.data.value);
      if (diff_label) {
        this.tag = { type, label: diff_label };
      } else {
        this.tag = null;
      }
      return;
    }

    if (type === product_tag.nft) {
      if (typeof this.data.value === 'number') {
        this.tag = { type, label: ET('mps2::product::tag::quantity_limited', { count: this.data.value }) };
      } else {
        const diff_label = this.getDiffTimeLabel(this.data.value);
        if (diff_label) {
          this.tag = { type, label: diff_label };
        }
      }
    }
  }

  private getDiffTimeLabel(date: Date): string | null {
    const diff = ProductTag.getDiffTime(date);
    // 4일 이상이면 숨겨진다.
    if (diff.day >= 4 || diff.second < 0) {
      return null;
    }
    // 1일 이상
    if (diff.day > 0) {
      return ET('mps2::product::tag::period_limited_day', diff);
    }
    return ET('mps2::product::tag::period_limited', { time: this.getDiffedTimeLabel(diff) });
  }

  private getUpcomingLabel(date: Date): string | null {
    const diff_in_seconds = differenceInSeconds(new Date(this.data.value), new Date());
    if (diff_in_seconds < 0) {
      return null;
    }
    const diff = ProductTag.getDiffTime(date);

    const ONE_HOUR = 3600;
    if (diff_in_seconds >= ONE_HOUR * 24 * 4) {
      return ET('mps2::product::tag::upcoming');
    }
    if (diff_in_seconds >= ONE_HOUR * 24) {
      return ET('mps2::product::tag::upcoming_day', diff);
    }
    if (diff_in_seconds >= ONE_HOUR) {
      return ET('mps2::product::tag::upcoming_hour', diff);
    }
    if (diff_in_seconds > 60) {
      return ET('mps2::product::tag::upcoming_min', diff);
    }
    return ET('mps2::product::tag::upcoming_soon', diff);
  }

  static getDiffTime(date: Date): { day: number; hour: number; minute: number; second: number } {
    const { day, hour, minute, second } = UtilS.differenceTimeInUnits(
      ['day', 'hour', 'minute', 'second'],
      new Date(date),
      new Date(),
      true,
    );
    return { day, hour, minute, second };
  }

  private getDiffedTimeLabel(diff: ReturnType<typeof ProductTag.getDiffTime>) {
    return `${padWithZero(diff.hour, 2)}:${padWithZero(diff.minute, 2)}:${padWithZero(diff.second, 2)}`;
  }

  static isQuantityLimitedTagVisible(
    data: Pick<ProductCardData, 'quantity' | 'order_count' | 'is_quantity_public'>,
  ): boolean {
    const { quantity, order_count, is_quantity_public } = data;
    const stock = quantity - order_count;
    return stock > 0 && stock < 100 && !!is_quantity_public;
  }

  static isPeriodLimitedTagVisible(data: Pick<ProductCardData, 'sell_start_at' | 'sell_end_at'>): boolean {
    // 기간한정 아님
    if (!ProductCardHelper.isPeriodLimited(data)) {
      return false;
    }

    const diff = ProductTag.getDiffTime(data.sell_end_at);
    // 기간한정 상품 종료까지 96시간 이상 남아있으면 숨겨진다.
    if (diff.day >= 4) {
      return false;
    }

    return !ProductCardHelper.isBeforeSale(data) && !ProductCardHelper.isEndedSale(data);
  }
}
