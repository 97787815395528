import { go } from 'fxjs/es';
import { GoodsTypeConstantS } from '../../../GoodsType/S/Constant/module/GoodsTypeConstantS.js';
import { GoodsTypeS } from '../../../GoodsType/S/Function/module/GoodsTypeS.js';
import { discountedPrice } from '../../../ProductColorPrice/S/fs.js';
import { UtilNumberS } from '../../../Util/Number/S/Function/module/UtilNumberS.js';
import { UtilS } from '../../../Util/S/Function/module/UtilS.js';

export const calcCrewMargins = (product, quantity, discount_rate, without_bonus_profit) => {
  const price_map = discountedPrice(product, quantity, undefined, undefined, true);

  const price = without_bonus_profit ? price_map.original_one : price_map.discounted_one;

  return Math.floor(discount_rate ? (price * discount_rate * quantity) / 100 : 0);
};

// 일단 USD 기준으로만, 추후에 환율 별로 소수점 몇 자리까지 바꿔야 하는지 체크 필요
export const exchangeCurrency = (adjustment) => {
  return UtilS.floorTo(
    -2,
    UtilNumberS.divDecimal(adjustment.profit + adjustment.bonus_profit, adjustment.exchange_rate),
  );
};

/* 기본 수수료율
  mps: 0, pbg: 3.3%, tpl: 15%, dtg: 12.5%
  부가세 10% 포함 퍼센테이지
  mps: 0, pbg: 3.63%, tpl: 16.5%, dtg: 13.75%
*/
const GOODS_TYPE_FEE = {
  v1: {
    [GoodsTypeConstantS.MPS.id]: 0,
    [GoodsTypeConstantS.PBG.id]: 3.63,
    [GoodsTypeConstantS.TPL.id]: 16.5,
    [GoodsTypeConstantS.DTG.id]: 13.75,
  },
};

export const getStoresFeeV1ByGoodsTypeId = (goods_type_id) => {
  return GOODS_TYPE_FEE.v1[goods_type_id];
};

export const getStoresInitFeeDate = () => {
  return {
    start_at: new Date(),
    end_at: new Date('2999-12-31T23:59:59+09:00'),
  };
};

export const getAdjustmentFee = ({ goods_type_id, user_products_stores_fee, user_products_tpl_fee }) => {
  const stores_fee = user_products_stores_fee?._?.stores_fee;
  return go(
    goods_type_id,
    () => {
      if (GoodsTypeS.isMps(goods_type_id)) {
        return stores_fee?.mps_fee_ratio;
      }
      if (GoodsTypeS.isPbg(goods_type_id)) {
        return stores_fee?.pbg_fee_ratio;
      }
      if (GoodsTypeS.isTpl(goods_type_id)) {
        const tpl_fee = user_products_tpl_fee?._?.tpl_fee;
        return tpl_fee?.ratio ?? stores_fee?.tpl_fee_ratio;
      }
      if (GoodsTypeS.isDtg(goods_type_id)) {
        return stores_fee?.dtg_fee_ratio;
      }
    },
    (user_product_fee) => {
      /* TPL 때문에 마지막에 확인해야한다*/
      return user_product_fee ?? getStoresFeeV1ByGoodsTypeId(goods_type_id) ?? 0;
    },
  );
};

export const isAdjFeeDate = (paid_at) => {
  /* 2025-03-03 00:00:00 +09:00 KST 이후에 구매된 상품은 새로운 수수료 적용 */
  const timestamp_2025_03_03 = 1740927600000;
  return new Date(paid_at).getTime() > timestamp_2025_03_03;
};
