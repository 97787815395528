import { type Html } from 'rune-ts';
import { MembershipIcon } from '../../../atoms/Icon';
import { ProductBadgeBase, ProductBadgeBaseData } from '../ProductBadgeBase';
import { IProductBadge } from '../interface';
import { isUndefined } from '@fxts/core';
import { ProductBadgeStyleHelper } from '../ProductBadgeStyleHelper';

export class MembershipBadge extends ProductBadgeBase<undefined> implements IProductBadge {
  constructor(data: ProductBadgeBaseData<undefined>, styler: ProductBadgeStyleHelper) {
    super(data, styler);

    if (isUndefined(this.data.option.hide_label)) {
      this.data.option.hide_label = true;
    }
  }

  setLabel(): void {
    this.label = ET('mps2::product::badge::membership');
  }

  isTimerNeeded(): boolean {
    return false;
  }

  getIcon(): Html | string | null {
    return MembershipIcon;
  }
}
