import { type Html } from 'rune-ts';
import { ProductBadgeBase } from '../ProductBadgeBase';
import { IProductBadge } from '../interface';

export class QuantityLimitedBadge extends ProductBadgeBase<number> implements IProductBadge {
  setLabel(): void {
    this.label = ET('mps2::product::badge::quantity_limited', { count: this.data.value });
  }

  isTimerNeeded(): boolean {
    return false;
  }

  getIcon(): Html | string | null {
    return null;
  }
}
