import { each, find, pipe } from '@fxts/core';
import { $prev, $qsa } from 'fxdom/es';

/**
 * hydration되지 않은 View를 찾아서 hydration 해줍니다.
 * hydrationRune(ViewClass1, ViewClass2, ViewClass3);
 * @param views
 */
export const hydrationRune = (...views) => {
  return pipe(
    $qsa('script.__RUNE_DATA__'),
    each((rune_script_el) => {
      const rune_view_el = $prev(rune_script_el);
      const MatchedView = find((view) => rune_view_el.dataset.rune === view.name, views);
      // if (!MatchedView)
      //   console.error(
      //     `[Rune] No matching View found for element with dataset.rune: ${rune_view_el.dataset.rune}`,
      //   );
      MatchedView?.createAndHydrate(rune_view_el);
    }),
  );
};
