import { type Html } from 'rune-ts';
import { ClockFillIcon } from '../../../atoms/Icon';
import { ProductBadgeBase } from '../ProductBadgeBase';
import { IProductBadge } from '../interface';

export class PeriodLimitedBadge extends ProductBadgeBase<Date> implements IProductBadge {
  setLabel(): void {
    const now = new Date();
    const diff = ProductBadgeBase.diffDate(this.data.value, now);

    // 4일 이상이면 숨겨진다.
    if (diff.day >= 4 || diff.second < 0) {
      this.label = ET('mps2::product::badge::upcoming');
      return;
    }
    if (diff.day > 0) {
      this.label = ET('mps2::product::badge::period_limited_day', diff);
      return;
    }
    if (diff.hour > 0) {
      this.label = ET('mps2::product::badge::period_limited_hour', diff);
      return;
    }
    if (diff.minute > 0) {
      this.label = ET('mps2::product::badge::period_limited_minute', diff);
      return;
    }
    this.label = ET('mps2::product::badge::period_limited_second', diff);
  }

  isTimerNeeded(): boolean {
    return true;
  }

  getIcon(): Html | string | null {
    return ClockFillIcon;
  }
}
