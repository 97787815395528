import {
  PRODUCT_SALE_STATUS,
  ProductCardData,
  ProductSaleStatusType,
} from '../../../../features/ProductList/type';

type NonNullable<T> = T extends null ? never : T;

type RemoveNull<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

type UpcomingData = Pick<ProductCardData, 'sell_start_at'>;

type PeriodLimitedData = Pick<ProductCardData, 'sell_end_at'>;
type QuantityLimitedData = Pick<ProductCardData, 'quantity'>;
type OutOfStockData = Pick<ProductCardData, 'quantity' | 'order_count'>;

export type SoldOutData = PeriodLimitedData &
  QuantityLimitedData &
  Pick<ProductCardData, 'out_of_stock' | 'order_count'>;

export class ProductCardHelper {
  private constructor() {
    // 인스턴스 생성 방지
    throw new Error(`ProductCardHelper is static`);
  }

  static isLimited(data: QuantityLimitedData & PeriodLimitedData) {
    return ProductCardHelper.isQuantityLimited(data) || ProductCardHelper.isPeriodLimited(data);
  }

  /**
   * 수량한정 상품인지 확인하는 함수
   */
  static isQuantityLimited(data: QuantityLimitedData) {
    return data.quantity > 0;
  }

  /**
   * 판매 종료일이 설정된 상품인지 확인
   */
  static isPeriodLimited(data: PeriodLimitedData): data is RemoveNull<PeriodLimitedData> {
    return Boolean(data.sell_end_at);
  }

  /**
   * 판매 시작 전인지 확인
   */
  static isBeforeSale(data: UpcomingData): data is RemoveNull<UpcomingData> {
    return Boolean(data.sell_start_at && new Date(data.sell_start_at) > new Date());
  }

  /**
   * 판매 종료일 지남
   */
  static isEndedSale(data: PeriodLimitedData): data is RemoveNull<PeriodLimitedData> {
    // 종료일이 설정되지 않은 경우
    if (!ProductCardHelper.isPeriodLimited(data)) {
      return false;
    }
    // 현재 시간이 종료일 이후인지 확인
    return new Date() > new Date(data.sell_end_at);
  }

  /**
   * 한정 수량이 다 팔림
   */
  static isLimitedQuantitySoldOut(data: OutOfStockData): boolean {
    return ProductCardHelper.isQuantityLimited(data) && data.order_count >= data.quantity;
  }

  /**
   * 품절인지 확인하는 함수, 아래 조건 OR
   * - 1. 재고 수량 관리가 다 팔린 경우
   * - 2. "수량한정"일 때 잔여수량이 없는 경우
   * - 3. 종료일이 설정되어 있는데, 종료일이 지난 경우
   */
  static isSoldOut(data: SoldOutData): boolean {
    return (
      /* 1. 재고 수량 관리가 다 팔린 경우 */
      data.out_of_stock ||
      ProductCardHelper.isLimitedQuantitySoldOut(data) ||
      ProductCardHelper.isEndedSale(data)
    );
  }

  /**
   * 판매 상태를 확인하는 함수
   */
  public static getSaleStatus(
    data: Pick<ProductCardData, 'sell_start_at' | 'sell_end_at'>,
  ): ProductSaleStatusType {
    const now = new Date();
    const end_at = data.sell_end_at ? new Date(data.sell_end_at) : null;

    if (ProductCardHelper.isBeforeSale(data)) {
      return PRODUCT_SALE_STATUS.BEFORE_SALE;
    }

    if (end_at) {
      if (now > end_at) {
        return PRODUCT_SALE_STATUS.ENDED_SALE;
      } else {
        return PRODUCT_SALE_STATUS.LIMITED_SALE;
      }
    }

    return PRODUCT_SALE_STATUS.ON_SALE;
  }
}
