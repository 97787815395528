export const COLLABO_TYPES = [
  '', // marpple
  'creator', // marpple shop
  'line',
];

export const COLLABO_TYPE = {
  marpple: '',
  marppleShop: 'creator',
  line: 'line',
};

export const COLLABO_TYPE_LABEL = {
  '': '마플',
  creator: '샵',
  line: '라인',
};

export const MARPPLE = 'Marpple';
export const LINE = 'Line';
export const MARPPLESHOP = 'Shop';
export const SERVICE_KO_NAMES = {
  MP: '마플',
  MPS: '마플샵',
  CIETY: 'CIETY',
};
