import { SERVICE_APP_NAME } from '../Constant/service_app_name.js';

export const isNessApp = () => {
  return G.SERVICE_APP_NAME === SERVICE_APP_NAME.NESS;
};
export const isMarppleShopApp = () => {
  return G.SERVICE_APP_NAME === SERVICE_APP_NAME.MARPPLE_SHOP;
};

export const isNboxApp = () => {
  return G.SERVICE_APP_NAME === SERVICE_APP_NAME.NBOX;
};

export const getServiceAppName = () => {
  return G.SERVICE_APP_NAME;
};
