import { html, View } from 'rune-ts';
import { ProductBadgeOption, ProductBadgeType, ProductCardData } from '../../../../features/ProductList/type';
import klass from './ProductBadgeList.module.scss';
import { ProductCardHelper } from '../ProductCard/ProductCardHelper';
import { ProductBadgeHelper } from '../ProductBadge/ProductBadgeHelper';
import { compact, map, pipe, take, toArray } from '@fxts/core';
import { product_badge } from '../../../../features/ProductList/constant';
import { ProductBadgeBase } from '../ProductBadge/ProductBadgeBase';
import { ProductBadgeFactory } from '../ProductBadge/ProductBadgeFactory';

export type ProductBadgeListData = Pick<
  ProductCardData,
  | 'is_public'
  | 'ranking'
  | 'sell_start_at'
  | 'sell_end_at'
  | 'quantity'
  | 'order_count'
  | 'is_quantity_public'
  | 'out_of_stock'
  | 'is_token_gate'
  | 'ordered_at'
  | 'is_membership'
>;

interface ProductBadgeListOption extends ProductBadgeOption {
  /**
   * @description 표시할 최대 뱃지 수
   * @default Infinity
   */
  max_length?: number;

  klass?: string;
}

export type ProductBadgeListOverrideOption = {
  [K in ProductBadgeType]?: Partial<Omit<ProductBadgeOption, 'is_mobile'>>;
};

export class ProductBadgeList extends View<Readonly<ProductBadgeListData>> {
  BadgeViews: ProductBadgeBase[];

  private interval_timer_ids: Array<ReturnType<typeof setInterval>> = [];

  constructor(
    data: Readonly<ProductBadgeListData>,
    private readonly option: Readonly<ProductBadgeListOption>,

    /**
     * 특정 뱃지의 옵션을 오버라이드 하고 싶을 때 사용합니다.
     * `option`의 값에 해당 속성을 override 합니다.
     */
    readonly override_option: Readonly<ProductBadgeListOverrideOption> = {},
  ) {
    super(data, option);
    const { klass, ..._option } = option;
    this.BadgeViews = pipe(
      this.setProductBadges(data, _option, override_option),
      take(option.max_length ?? Infinity),
      toArray,
    );
  }

  override template() {
    const hidden_klass = this.BadgeViews.length ? '' : klass.hidden;
    return html`<div class="${this.option.klass ?? ''} ${klass.badge_container} ${hidden_klass}">
      ${this.BadgeViews}
    </div>`;
  }

  public startBadgesTimer(externalCallback?: () => void): Array<ReturnType<typeof setInterval>> {
    if (!this.option.is_lazy_timer) {
      throw new Error('ProductBadgeList에 is_lazy_timer옵션이 적용되어 있지 않습니다.');
    }

    return (this.interval_timer_ids = pipe(
      this.BadgeViews,
      map((badgeView) => badgeView.startLazyIntervalTimer(externalCallback)),
      compact,
      toArray,
    ));
  }

  /**
   * 상품 뱃지를 생성하여 반환하는 함수입니다.
   * - 개수 제한 없이 상품이 가질 수 있는 모든 뱃지를 반환하므로, `take(n)`을 하여 사용하도록 합니다.
   */
  private setProductBadges(
    data: Readonly<ProductBadgeListData>,
    option: Readonly<ProductBadgeListOption>,
    override_option: Readonly<ProductBadgeListOverrideOption>,
  ): ProductBadgeBase[] {
    const badges: ProductBadgeBase[] = [];

    const mergeOption = (type: ProductBadgeType) => {
      return {
        ...option,
        ...override_option[type],
      };
    };

    // `Closed` 비공개 상품일 경우 노출, 단독으로 표시
    if (!data.is_public) {
      badges.push(
        ProductBadgeFactory.create(product_badge.closed, {
          value: undefined,
          option: mergeOption(product_badge.closed),
        }),
      );
      return badges;
    }

    // `Realtime` 실시간 뱃지, 주입한 경우에만 표시
    if (data.ordered_at) {
      badges.push(
        ProductBadgeFactory.create(product_badge.realtime, {
          value: data.ordered_at,
          option: mergeOption(product_badge.realtime),
        }),
      );
    }

    // `Ranking` 랭킹, 주입한 경우에만 표시
    if (data.ranking) {
      badges.push(
        ProductBadgeFactory.create(product_badge.ranking, {
          value: data.ranking,
          option: mergeOption(product_badge.ranking),
        }),
      );
    }

    // `Sold out` 품절
    // `Upcoming`인 경우에는 `Sold out`말고 `Upcoming` 뱃지를 노출한다.
    if (ProductCardHelper.isSoldOut(data) && !ProductCardHelper.isBeforeSale(data)) {
      badges.push(
        ProductBadgeFactory.create(product_badge.sold_out, {
          value: undefined,
          option: mergeOption(product_badge.sold_out),
        }),
      );
    }

    // `Membership` 뱃지, 주입한 경우에만 표시
    if (data.is_membership) {
      badges.push(
        ProductBadgeFactory.create(product_badge.membership, {
          value: undefined,
          option: mergeOption(product_badge.membership),
        }),
      );
    }

    // `Upcoming` 출시 예정
    if (ProductCardHelper.isBeforeSale(data)) {
      badges.push(
        ProductBadgeFactory.create(product_badge.upcoming, {
          value: data.sell_start_at,
          option: mergeOption(product_badge.upcoming),
        }),
      );
    }

    if (ProductCardHelper.isLimited(data) && !ProductCardHelper.isBeforeSale(data)) {
      const { sell_end_at, quantity } = data;

      // `Limited` 기간 한정 OR 수량 한정
      badges.push(
        ProductBadgeFactory.create(product_badge.limited, {
          value: undefined,
          option: mergeOption(product_badge.limited),
        }),
      );

      // 수량 한정이면서 기간 한정 일 때
      if (ProductBadgeHelper.isBothQuantityAndPeriodLimitedBadgeVisible(data) && sell_end_at) {
        const period_limited_badge_priority = ProductBadgeHelper.getPeriodLimitedBadgePriority(sell_end_at);
        const quantity_limited_badge_priority = ProductBadgeHelper.getQuantityLimitedBadgePriority(quantity);

        // 기간 한정뱃지가 수량한정보다 먼저 보여지는 조건
        if (period_limited_badge_priority < quantity_limited_badge_priority) {
          badges.push(
            ProductBadgeFactory.create(product_badge.period_limited, {
              value: sell_end_at,
              option: mergeOption(product_badge.period_limited),
            }),
          );
        } else {
          badges.push(
            ProductBadgeFactory.create(product_badge.quantity_limited, {
              value: data.quantity - data.order_count,
              option: mergeOption(product_badge.quantity_limited),
            }),
          );
        }
      } else {
        // 수량한정 only
        if (ProductBadgeHelper.isQuantityLimitedBadgeVisible(data)) {
          badges.push(
            ProductBadgeFactory.create(product_badge.quantity_limited, {
              value: data.quantity - data.order_count,
              option: mergeOption(product_badge.quantity_limited),
            }),
          );
        }
        // 기간한정 only
        else if (ProductBadgeHelper.isPeriodLimitedBadgeVisible(data) && sell_end_at) {
          badges.push(
            ProductBadgeFactory.create(product_badge.period_limited, {
              value: sell_end_at,
              option: mergeOption(product_badge.period_limited),
            }),
          );
        }
      }
    }

    // `NFT`
    if (data.is_token_gate) {
      badges.push(
        ProductBadgeFactory.create(product_badge.nft, {
          value: undefined,
          option: mergeOption(product_badge.nft),
        }),
      );
    }

    return badges;
  }

  public clearIntervalTimers(): void {
    this.interval_timer_ids.forEach((timer_id) => {
      clearInterval(timer_id);
    });

    // flush
    this.interval_timer_ids = [];
  }
}
