import { type Html, html, View } from 'rune-ts';
import { ProductBadgeOption } from '../../../../features/ProductList/type';
import { IProductBadge } from './interface';
import { ProductBadgeStyleHelper } from './ProductBadgeStyleHelper';
import { differenceTimeInUnits } from '../../../../../../modules/Util/S/Function/util';
import { ProductTagExpiredEvent } from '../ProductTag/ProductTag';

export type ProductBadgeBaseData<T = unknown> = {
  value: T;
  option: ProductBadgeOption;
};

export abstract class ProductBadgeBase<T = unknown>
  extends View<ProductBadgeBaseData<T>>
  implements IProductBadge
{
  protected label?: string;
  protected interval_timer_id: ReturnType<typeof setInterval> | null = null;
  protected readonly styler: Partial<ProductBadgeStyleHelper>;

  constructor(data: ProductBadgeBaseData<T>, styler?: ProductBadgeStyleHelper) {
    super(data);
    this.styler = styler ?? {};
    this.setLabel();
  }

  override template() {
    return html`<span class="${this.styler.identifier} ${this.styler.klass} ${this.data.option.klass || ''}">
      ${this.getIcon() ? html`<span class="${this.styler.icon_klass}">${this.getIcon()}</span>` : ''}
      ${!this.data.option.hide_label ? html`<span>${this.label}</span>` : ''}
    </span>`;
  }

  protected override onRender() {
    if (this.isTimerNeeded() && !this.data.option.is_lazy_timer) {
      this.interval_timer_id = this.startIntervalTimer();
    }
  }

  protected override onUnmount() {
    this.clearIntervalTimer();
  }

  public clearIntervalTimer(): void {
    if (this.interval_timer_id) {
      clearInterval(this.interval_timer_id);
      this.interval_timer_id = null;
    }
  }

  protected startIntervalTimer(externalCallback?: () => void): ReturnType<typeof setInterval> | null {
    if (!this.isTimerNeeded()) {
      this.clearIntervalTimer();
      return null;
    }

    return setInterval(() => {
      if (externalCallback) {
        externalCallback();
      }
      this.refreshBadge();
    }, 1000);
  }

  public startLazyIntervalTimer(externalCallback?: () => void): ReturnType<typeof setInterval> | null {
    if (!this.data.option.is_lazy_timer) {
      throw new Error('ProductBadge에 is_lazy_timer옵션이 적용되어 있지 않습니다.');
    }

    if (this.isTimerNeeded()) {
      return this.startIntervalTimer(externalCallback);
    }

    return null;
  }

  private refreshBadge() {
    this.setLabel();
    this.redraw();
    if (!this.label) {
      this.dispatchEvent(ProductTagExpiredEvent, {
        bubbles: true,
      });
      this.clearIntervalTimer();
    }
  }

  public static diffDate(
    from: Date,
    to: Date,
  ): { day: number; hour: number; minute: number; second: number } {
    return differenceTimeInUnits(['day', 'hour', 'minute', 'second'], new Date(from), new Date(to), true);
  }

  abstract setLabel(): void;
  abstract isTimerNeeded(): boolean;
  abstract getIcon(): Html | string | null;
}
