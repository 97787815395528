import { html } from 'rune-ts';
import {
  makeBankIcon,
  makeBookMarkIcon,
  makeDigitalIcon,
  makeInstagramIcon,
  makeMegaphoneIcon,
  makePBIcon,
  makePeopleIcon,
  makePODIcon,
  makeShopIcon,
  makeSmileIcon,
} from '../../../components/atoms/Icon';
import { CreatorStudioOnboardingActionTypeEnum, OnboardingItemOptions } from '../type';

export const CREATOR_STUDIO_ONBOARDING = 'CREATOR_STUDIO_ONBOARDING' as const;
export const CREATOR_STUDIO_ONBOARDING_ACTION_TYPE = {
  /** @description 샵 정보 입력하기 */
  FIRST_SHOP_INFO_SETTING: 'FIRST_SHOP_INFO_SETTING',
  /** @description 샵 꾸미기 */
  FIRST_SHOP_DECO_SETTING: 'FIRST_SHOP_DECO_SETTING',
  /** @description 크리에이터 정보 입력하기 */
  FIRST_CREATOR_INFO_SETTING: 'FIRST_CREATOR_INFO_SETTING',
  /** @description 계좌 정보 등록하기 */
  FIRST_BANK_INFO_SETTING: 'FIRST_BANK_INFO_SETTING',
  /** @description 마플샵 상품 등록하기 */
  FIRST_POD_PRODUCT_REGISTER: 'FIRST_POD_PRODUCT_REGISTER',
  /** @description 크리에이터 상품 등록하기 */
  FIRST_PB_PRODUCT_REGISTER: 'FIRST_PB_PRODUCT_REGISTER',
  /** @description 디지털/전자책 등록하기 */
  FIRST_DIGITAL_PRODUCT_REGISTER: 'FIRST_DIGITAL_PRODUCT_REGISTER',
  /** @description 대표 SNS 설정하기 */
  FIRST_SNS_SETTING: 'FIRST_SNS_SETTING',
  /** @description 응원하기로 소통하기 */
  FIRST_CHAT_CHEER: 'FIRST_CHAT_CHEER',
  /** @description 크리에이터 가이드 확인하기 */
  FIRST_CREATOR_GUIDE: 'FIRST_CREATOR_GUIDE',
} as const;
/** @description 온보딩 액션 보상 포인트 */
export const CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT = 300;
/** @description 온보딩 액션 마지막 기준 */
export const CREATOR_STUDIO_ONBOARDING_ACTION_LAST_INDEX = 9;

export const ONBOARDING_ITEMS_STATUS_INIT: Record<CreatorStudioOnboardingActionTypeEnum, boolean> = {
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_SHOP_INFO_SETTING]: false,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_SHOP_DECO_SETTING]: false,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_CREATOR_INFO_SETTING]: false,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_BANK_INFO_SETTING]: false,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_POD_PRODUCT_REGISTER]: false,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_PB_PRODUCT_REGISTER]: false,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_DIGITAL_PRODUCT_REGISTER]: false,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_SNS_SETTING]: false,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_CHAT_CHEER]: false,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_CREATOR_GUIDE]: false,
};

export const ONBOARDING_ITEMS_STATUS_COMPLETED: Record<CreatorStudioOnboardingActionTypeEnum, boolean> = {
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_SHOP_INFO_SETTING]: true,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_SHOP_DECO_SETTING]: true,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_CREATOR_INFO_SETTING]: true,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_BANK_INFO_SETTING]: true,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_POD_PRODUCT_REGISTER]: true,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_PB_PRODUCT_REGISTER]: true,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_DIGITAL_PRODUCT_REGISTER]: true,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_SNS_SETTING]: true,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_CHAT_CHEER]: true,
  [CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_CREATOR_GUIDE]: true,
};

export const ONBOARDING_ITEMS = (): OnboardingItemOptions[] => [
  {
    category: 'start',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_SHOP_INFO_SETTING,
    cate_index: 1,
    cate_title: ET('mps2::seller_studio::onBoarding::start::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::start::shop_info_setting::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::start::shop_info_setting::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::start::shop_info_setting::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::start::shop_info_setting::button_text'),
    makeIcon: makeSmileIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/b8e28bbe03cd5d2e13987aa26ce2421e447562a28.png',
    verification_url: 'settings/shop_info',
  },
  {
    category: 'start',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_SHOP_DECO_SETTING,
    cate_index: 2,
    cate_title: ET('mps2::seller_studio::onBoarding::start::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::start::shop_deco_setting::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::start::shop_deco_setting::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::start::shop_deco_setting::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::start::shop_deco_setting::button_text'),
    makeIcon: makeShopIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/77c4e81ac17beffdbba3b1a65710bf3e02dc693b5.png',
    verification_url: 'settings/styles',
  },
  {
    category: 'start',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_CREATOR_INFO_SETTING,
    cate_index: 3,
    cate_title: ET('mps2::seller_studio::onBoarding::start::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::start::creator_info_setting::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::start::creator_info_setting::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::start::creator_info_setting::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::start::creator_info_setting::button_text'),
    makeIcon: makePeopleIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/a1d66817a3ac508c5f29bad6453d0cd5047715cf3.png',
    verification_url: 'settings/ecommerce_info',
  },
  {
    category: 'start',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_BANK_INFO_SETTING,
    cate_index: 4,
    cate_title: ET('mps2::seller_studio::onBoarding::start::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::start::bank_info_setting::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::start::bank_info_setting::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::start::bank_info_setting::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::start::bank_info_setting::button_text'),
    makeIcon: makeBankIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/2fe0a6340bb1e44e7bb738023c476cf48b193ddd2.png',
    verification_url: 'settings/bank_account',
  },
  {
    category: 'sales',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_POD_PRODUCT_REGISTER,
    cate_index: 1,
    cate_title: ET('mps2::seller_studio::onBoarding::sales::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::sales::mps_product_sales::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::sales::mps_product_sales::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::sales::mps_product_sales::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::sales::mps_product_sales::button_text'),
    makeIcon: makePODIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/0973a0b494bf81734d81b68f8fb977b2b1e0ba0310.png',
    verification_url: 'settings/products?is_editor_open=true',
  },
  {
    category: 'sales',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_PB_PRODUCT_REGISTER,
    cate_index: 2,
    cate_title: ET('mps2::seller_studio::onBoarding::sales::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::sales::creator_product_sales::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::sales::creator_product_sales::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::sales::creator_product_sales::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::sales::creator_product_sales::button_text'),
    makeIcon: makePBIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/6aca561ae0f57512e59e4fea1e1e2223daff2b0b9.png',
    verification_url: 'settings/pb_products?is_editor_open=true',
  },
  {
    category: 'sales',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_DIGITAL_PRODUCT_REGISTER,
    cate_index: 3,
    cate_title: ET('mps2::seller_studio::onBoarding::sales::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::sales::digital_product_sales::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::sales::digital_product_sales::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::sales::digital_product_sales::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::sales::digital_product_sales::button_text'),
    makeIcon: makeDigitalIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/4d1769ce9e472c87f55941e4b6bad6c47c7a91b91.png',
    verification_url: 'settings/digital_products?is_editor_open=true',
  },
  {
    category: 'debut',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_SNS_SETTING,
    cate_index: 1,
    cate_title: ET('mps2::seller_studio::onBoarding::debut::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::debut::sns_setting::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::debut::sns_setting::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::debut::sns_setting::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::debut::sns_setting::button_text'),
    makeIcon: makeInstagramIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/ee838b8a46896bb7849f099b2598980a5b443efe4.png',
    verification_url: 'settings/shop_info',
  },
  {
    category: 'debut',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_CHAT_CHEER,
    cate_index: 2,
    cate_title: ET('mps2::seller_studio::onBoarding::debut::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::debut::chat_cheer::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::debut::chat_cheer::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::debut::chat_cheer::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::debut::chat_cheer::button_text'),
    makeIcon: makeMegaphoneIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/22deda9483eeabf79839b178b7be87a3b37da2506.png',
    verification_url: 'community',
  },
  {
    category: 'debut',
    event_type: CREATOR_STUDIO_ONBOARDING,
    action_type: CREATOR_STUDIO_ONBOARDING_ACTION_TYPE.FIRST_CREATOR_GUIDE,
    cate_index: 3,
    cate_title: ET('mps2::seller_studio::onBoarding::debut::cate_title'),
    item_title: ET('mps2::seller_studio::onBoarding::debut::creator_guide::item_title'),
    item_desc: ET('mps2::seller_studio::onBoarding::debut::creator_guide::item_desc'),
    item_full_desc: html.preventEscape(
      ET('mps2::seller_studio::onBoarding::debut::creator_guide::item_full_desc'),
    ),
    button_text: ET('mps2::seller_studio::onBoarding::debut::creator_guide::button_text'),
    makeIcon: makeBookMarkIcon,
    is_completed: false,
    point: CREATOR_STUDIO_ONBOARDING_ACTION_REWARD_POINT,
    popup_image_url:
      '//s3.marpple.co/files/u_2283830/2024/11/original/edb9ce4082f2cba8efd5f4b7554a1e81a10c2a517.png',
    verification_url: 'https://kr.marppleshop.help/',
  },
];
