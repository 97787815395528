import { type Html } from 'rune-ts';
import { ProductBadgeBase } from '../ProductBadgeBase';
import { IProductBadge } from '../interface';

export class ClosedBadge extends ProductBadgeBase<undefined> implements IProductBadge {
  setLabel(): void {
    this.label = ET('mps2::product::badge::closed');
  }

  isTimerNeeded(): boolean {
    return false;
  }

  getIcon(): Html | string | null {
    return null;
  }
}
