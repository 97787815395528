import { html, strMap } from 'fxjs/es';
import { requiringBorder } from '../../../../../Maker/F/Product/bpc_tmpl.js';
import { UtilImageS } from '../../../../../Util/Image/S/Function/module/UtilImageS.js';
import { UtilS } from '../../../../../Util/S/Function/module/UtilS.js';
import { OMPCoreAtomTmplS } from '../../../Atom/S/Tmpl/module/OMPCoreAtomTmplS.js';

export const priceTemplatePc = (is_use_origin_price, original_price_text, price_ranges, bp_discount_set) => {
  // if (bp_discount_set?.type === BpDiscountSetConstantS.TYPE.MP_WOW) { // MP_WOW 의 가격 표기 방식이 다르다면
  // return ... ;
  // }
  return strMap(({ price_text, percent, start }) => {
    const range_count = is_use_origin_price
      ? TT('cell::product::prod_1_9', { n: UtilS.commify(start) })
      : start > 1
        ? TT('cell::product::prod_1_6', { n: UtilS.commify(start) })
        : TT('cell::product::prod_1_5', { n: 1 });

    const sale_percent = html`${!is_use_origin_price && start > 1 && percent
      ? TT('cell::product::prod_1_7', { n: percent })
      : ''}
    ${is_use_origin_price ? TT('cell::product::prod_1_10') : ''}`;

    return html`
      <div class="omp-cell__product-text__price-wrapper">
        ${UtilS.htmlIf(
          sale_percent,
          html`<span class="omp-cell__product-text__sale-percent${is_use_origin_price ? ' no-margin' : ''}">
            ${sale_percent}
          </span>`,
        )}
        <div class="omp-cell__product-text__minimum-condition">${range_count}</div>
        <div class="omp-cell__product-text__minimum-price">
          ${!is_use_origin_price
            ? `${TT('product::list::list_13_1', { price: price_text })}
                ${OMPCoreAtomTmplS.discountedIcon()}`
            : ''}
        </div>
      </div>
    `;
  }, price_ranges);
};

/**
 * Create a product component with the specified options.
 *
 * @param {Object} options - The options for the product.
 * @param {number} [options.id] - The ID of the product.
 * @param {number} [options.no] - The OrderNumber of the product.
 * @param {number} [options.price] - The Price of the product.
 * @param {string} options.model_thumb_url - The URL of the product thumbnail.
 * @param {'product'|'model'} [options.thumbnail_bg='product'] - The background of the product thumbnail (optional, default: 'product').
 * @param {Array} [options.badges=[]] - The header options for the product (optional).
 * @param {Object} [options._.brand = { name: '' }]- The name of the product brand (optional, default: '').
 * @param {string} [options.name=''] - The name of the product (optional, default: '').
 * @param {string} [options.one_line_description=''] - The description of the product (optional, default: '').
 * @param {Array} [options._.base_product_colors=[]] - The available colors of the product (optional).
 * @param {string} [options.minimum_price_text=''] - The minimum price text of the product (optional, default: '').
 * @param {string} [options.original_price_text=''] - The price text of the product (optional, default: '').
 * @param {Object} [options._.base_products_like={}] - Whether the product is in the wish list (optional, default: false).
 * @returns {string} The HTML markup for the product component.
 */
export const product = ({
  id,
  no,
  thumbnail_bg = 'product',
  one_line_description = '',
  minimum_price_text = '',
  original_price_text = '',
  price,
  name,
  model_thumb_url,
  badges,
  is_color_for_list,
  is_bpc_hidden,
  is_discount,
  price_ranges,
  is_one,
  is_use_origin_price,
  _: {
    brand,
    base_product_colors,
    base_products_like,
    reviews,
    base_products_custom_badges,
    bp_discount_set,
  },
} = {}) => {
  const [individual_price, ...bundle_prices] = price_ranges; // [개별 가격, ...묶음 가격]
  const is_color_overflow = base_product_colors.length > 15;
  if (is_color_overflow) {
    base_product_colors.length = 15;
  }
  const review_txt = TT('cell::product::prod_1_2');
  return html`
    <div data-id="${id}" data-price="${price}" data-no="${no}" class="omp-cell__product">
      <a href="/${TT.lang}/product/detail?bp_id=${id}" class="omp-cell__product-anchor">
        <div class="omp-cell__product-thumbnail" data-bg="${thumbnail_bg}">
          ${model_thumb_url
            ? html`<img
                class="omp-image-lazy"
                data-src="${UtilImageS.getResized70Webp(model_thumb_url, 400)}"
                alt="${name}"
              />`
            : ''}
          <div class="omp-cell__product-badge-wrapper">
            <div class="left">${strMap(OMPCoreAtomTmplS.badge, badges)}</div>
          </div>
          <div class="omp-cell__product-create-wrapper">
            ${OMPCoreAtomTmplS.actionButton({
              klass: 'omp-cell__product-create',
              text: TT('product::custom::start'),
            })}
          </div>
        </div>
        <div class="omp-cell__product-text">
          <div class="omp-cell__product-group">
            ${brand.name ? html`<div class="omp-cell__product-text__brand-name">${brand.name}</div>` : ''}
            <div class="omp-cell__product-text__product-name">${name}</div>
            ${one_line_description
              ? html`<div class="omp-cell__product-text__product-description">${one_line_description}</div>`
              : ''}
          </div>
          ${!is_bpc_hidden && is_color_for_list && base_product_colors.length
            ? html` <div class="omp-cell__product-text__product-color">
                ${strMap(({ color_code, color_code2 }) => {
                  return html`
                    <div
                      class="omp-cell__product-text__product-color-point"
                      data-color_code="${color_code}"
                      style="background: ${color_code2
                        ? `linear-gradient(135deg, ${color_code} 50%, ${color_code2} 50%)`
                        : color_code}"
                      need_ccc="${requiringBorder([color_code, color_code2])}"
                    ></div>
                  `;
                }, base_product_colors)}
                ${is_color_overflow
                  ? html` <div class="omp-cell__product-text__product-color-plus">
                      ${OMPCoreAtomTmplS.plusIcon({ color: 'GY_80' })}
                    </div>`
                  : ''}
              </div>`
            : ''}
          <div class="omp-cell__product-group-price">
            <div class="omp-cell__product-text__price mp_currency">
              ${UtilS.commify(individual_price.price_text)}
            </div>
            ${UtilS.htmlIf(
              is_use_origin_price && false /* 정가 표시 안하기로 함 */,
              html`
                <div class="omp-cell__product-text__price-wrapper">
                  <div class="omp-cell__product-text__minimum-condition">
                    ${TT('cell::product::prod_1_8')}
                  </div>
                  <div class="omp-cell__product-text__minimum-price">${original_price_text}</div>
                </div>
              `,
            )}
            ${priceTemplatePc(is_use_origin_price, original_price_text, bundle_prices, bp_discount_set)}
          </div>
          ${Object.keys(reviews).length
            ? html`
                <div class="omp-cell__product-text__meta-wrapper">
                  <div class="omp-cell__product-text__meta-item omp-cell__product-text__meta-item-score">
                    ${OMPCoreAtomTmplS.starIcon({ style: { color: 'OG' } })}
                    <span class="omp-cell__product-text__meta-value">
                      ${UtilS.commify(parseFloat(reviews.score))}
                    </span>
                  </div>
                  <div class="omp-cell__product-text__meta-item">
                    <span class="omp-cell__product-text__meta-value">(${UtilS.commify(reviews.count)})</span>
                  </div>
                </div>
              `
            : ''}

          <div class="omp-cell__product-text__tips">
            ${is_one
              ? html`<div class="omp-cell__product-text__tip">${TT('cell::product::prod_1_3')}</div>`
              : html`<div class="omp-cell__product-text__tip">
                  ${TT('cell::product::prod_1_11', { n: individual_price.start })}
                </div>`}
            ${
              ''
              // <div class="omp-cell__product-text__tip light-orange">
              //   ${OMPCoreAtomTmplS.discountIcon()}
              //   ${TT('cell::product::prod_1_4', { count: 100, price: UtilS.commify(1000) })}
              // </div>
            }
          </div>

          <div
            class="omp-cell__product-text__heart omp-cell__bp-like"
            data-active="${id == base_products_like?.base_product_id}"
            data-base_product_id="${id}"
          >
            ${OMPCoreAtomTmplS.heartButton({
              active: id == base_products_like?.base_product_id,
              color: 'gray',
            })}
          </div>
        </div>
      </a>
    </div>
  `;
};

const priceTemplateMo = (is_use_origin_price, original_price_text, price_ranges, bp_discount_set) => {
  // if (bp_discount_set?.type === BpDiscountSetConstantS.TYPE.MP_WOW) { // MP_WOW 의 가격 표기 방식이 다르다면
  // return ...;
  // }
  return strMap(({ price_text, percent, start }) => {
    const sale_percent = html`${is_use_origin_price ? TT('cell::product::prod_1_10') : ''}
    ${!is_use_origin_price && start > 1 && percent ? `${percent}%` : ''}`;

    return html`
      <div class="omp-cell__product-mo-text__price-item">
        ${UtilS.htmlIf(
          sale_percent,
          html` <div class="omp-cell__product-mo-text__sale-percent">${sale_percent}</div> `,
        )}
        <div class="omp-cell__product-mo-text__price-label">
          ${is_use_origin_price
            ? TT('cell::product::prod_1_9', { n: UtilS.commify(start) })
            : TT('cell::product::prod_1_6', { n: UtilS.commify(start) })}
        </div>
        ${!is_use_origin_price
          ? html`
              <div class="omp-cell__product-mo-text__price-value">
                ${TT('product::list::list_13_1', {
                  price: price_text,
                })}
                ${OMPCoreAtomTmplS.discountedIcon()}
              </div>
            `
          : html``}
      </div>
    `;
  }, price_ranges);
};

export const productMo = ({
  id,
  thumbnail_bg = 'product',
  minimum_price_text = '',
  original_price_text = '',
  name,
  model_thumb_url,
  price,
  no,
  is_color_for_list,
  is_bpc_hidden,
  is_discount,
  one_line_description,
  price_ranges,
  is_one,
  is_use_origin_price,
  badges,
  _: { brand, base_product_colors, base_products_like, reviews, bp_discount_set },
} = {}) => {
  const [individual_price, ...bundle_prices] = price_ranges; // [개별 가격, ...묶음 가격]
  const is_color_overflow = base_product_colors.length > 9;
  if (is_color_overflow) {
    base_product_colors.length = 9;
  }

  return html`
    <div data-id="${id}" data-price="${price}" data-no="${no}" class="omp-cell__product-mo">
      <a href="/${TT.lang}/product/detail?bp_id=${id}" class="omp-cell__product-mo-anchor">
        <div class="omp-cell__product-mo-thumbnail" data-bg="${thumbnail_bg}">
          ${model_thumb_url
            ? html`<img class="omp-image-lazy" data-src="${model_thumb_url}" alt="${name}" />`
            : ''}
          <div class="omp-cell__product-mo-top-badge-wrapper">${strMap(OMPCoreAtomTmplS.badge, badges)}</div>
          <div class="omp-cell__product-mo-bottom-badge-wrapper">
            <div
              class="omp-cell__product-mo-badge-wrapper omp-cell__bp-like"
              data-active="${id == base_products_like?.base_product_id}"
              data-base_product_id="${id}"
            >
              ${OMPCoreAtomTmplS.heartCircleButton({ active: id == base_products_like?.base_product_id })}
            </div>
          </div>
        </div>
        <div class="omp-cell__product-mo-text">
          <div class="omp-cell__product-group">
            ${brand.name ? html`<div class="omp-cell__product-mo-text__brand-name">${brand.name}</div>` : ''}
            <div class="omp-cell__product-mo-text__product-name">${name}</div>
            ${one_line_description
              ? html`<div class="omp-cell__product-mo-text__product-description">
                  ${one_line_description}
                </div>`
              : ''}
          </div>
          ${!is_bpc_hidden && is_color_for_list && base_product_colors.length
            ? html` <div class="omp-cell__product-mo-text__product-color">
                ${strMap(({ color_code, color_code2 }) => {
                  return html`
                    <div
                      class="omp-cell__product-mo-text__product-color-point"
                      data-color_code="${color_code}"
                      style="background: ${color_code2
                        ? `linear-gradient(135deg, ${color_code} 50%, ${color_code2} 50%)`
                        : color_code}"
                      need_ccc="${requiringBorder([color_code, color_code2])}"
                    ></div>
                  `;
                }, base_product_colors)}
                ${is_color_overflow
                  ? html` <div class="omp-cell__product-mo-text__product-color-plus">
                      ${OMPCoreAtomTmplS.plusIcon({ color: 'GY_80' })}
                    </div>`
                  : ''}
              </div>`
            : ''}
          <div class="omp-cell__product-mo-text__price-wrapper">
            <div class="omp-cell__product-mo-text__price mp_currency">
              ${UtilS.commify(individual_price.price_text)}
            </div>
            ${UtilS.htmlIf(
              is_use_origin_price && false /* 정가 표시 안하기로 함 */,
              html`
                <div class="omp-cell__product-mo-text__price-item">
                  <div class="omp-cell__product-mo-text__price-label">${TT('cell::product::prod_1_8')}</div>
                  <div class="omp-cell__product-mo-text__price-value">${original_price_text}</div>
                </div>
              `,
            )}
            ${priceTemplateMo(is_use_origin_price, original_price_text, bundle_prices, bp_discount_set)}
          </div>
          ${Object.keys(reviews).length
            ? html`
                <div class="omp-cell__product-mo-text__meta-wrapper">
                  <div
                    class="omp-cell__product-mo-text__meta-item omp-cell__product-mo-text__meta-item-score"
                  >
                    ${OMPCoreAtomTmplS.starIcon({ style: { color: 'OG' } })}
                    <span class="omp-cell__product-text__meta-value"
                      >${UtilS.commify(parseFloat(reviews.score))}</span
                    >
                  </div>
                  <div class="omp-cell__product-mo-text__meta-item">
                    <span class="omp-cell__product-mo-text__meta-value"
                      >(${UtilS.commify(reviews.count)})</span
                    >
                  </div>
                </div>
              `
            : ''}

          <div class="omp-cell__product-mo-text__tips">
            ${is_one
              ? html`<div class="omp-cell__product-mo-text__tip">${TT('cell::product::prod_1_3')}</div>`
              : html`<div class="omp-cell__product-mo-text__tip">
                  ${TT('cell::product::prod_1_11', { n: individual_price.start })}
                </div>`}
          </div>
        </div>
      </a>
    </div>
  `;
};

// use at DF
export const productSimple = ({
  thumbnail_bg = 'product',
  one_line_description = '',
  name,
  minimum_price_text = '',
  original_price_text = '',
  model_thumb_url,
} = {}) => {
  return html`
    <div class="omp-cell__product">
      <div class="omp-cell__product-anchor">
        <div class="omp-cell__product-thumbnail" data-bg="${thumbnail_bg}">
          ${model_thumb_url ? html`<img src="${model_thumb_url}" alt="${name}" />` : ''}
        </div>
        <div class="omp-cell__product-text">
          <div class="omp-cell__product-text__product-name">${name}</div>
          ${one_line_description
            ? html`<div class="omp-cell__product-text__product-description">${one_line_description}</div>`
            : ''}
          <div class="omp-cell__product-text__price-wrapper">
            ${original_price_text
              ? html`<div class="omp-cell__product-text__price">${original_price_text}</div>`
              : ''}
            ${minimum_price_text
              ? html`<div class="omp-cell__product-text__minimum-price">${minimum_price_text}</div>`
              : ''}
          </div>
        </div>
      </div>
    </div>
  `;
};
