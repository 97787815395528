/**
 * @param {Object} config - fetch 설정 객체
 * @param {number} [retryCount=0] - 재시도 횟수
 * @param {number} [timeout=60000] - 타임아웃 (밀리초)
 * @param {number} [retryInterval=5000] - 재시도 간격 (밀리초)
 * @returns {Promise & { abort: Function }} - fetch 요청을 수행하는 Promise와 abort 함수
 */

export const apiCall = (config, retryCount = 0, timeout = 60000, retryInterval = 5000) => {
  // eslint-disable-next-line no-undef
  const controller = new AbortController();

  const executeCall = async (retryAttempt = 0) => {
    // eslint-disable-next-line no-undef
    const timeoutId = setTimeout(() => controller.abort(new Error('Request timed out')), timeout);

    try {
      const { url, method, body, headers = {}, ...restConfig } = config;

      const processedBody = body && (typeof body === 'string' ? body : JSON.stringify(body));

      // eslint-disable-next-line no-undef
      const response = await fetch(url, {
        method,
        ...restConfig,
        signal: controller.signal,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
        ...(body && { body: processedBody }),
      });

      // eslint-disable-next-line no-undef
      clearTimeout(timeoutId);

      if (!response.ok) {
        const errorData = await response.text().catch(() => '');
        throw new Error(`HTTP error! Status: ${response.status}, Details: ${errorData}`);
      }

      return await response.json();
    } catch (error) {
      // eslint-disable-next-line no-undef
      clearTimeout(timeoutId);

      if (error.name === 'AbortError') {
        throw error;
      }

      if (retryAttempt < retryCount) {
        console.warn(`Retrying request... (${retryAttempt + 1}/${retryCount})`);
        // eslint-disable-next-line no-undef
        await new Promise((resolve) => setTimeout(resolve, retryInterval));
        return executeCall(retryAttempt + 1);
      }

      console.error('API request failed:', error);
      throw error;
    }
  };

  const promise = executeCall();

  return Object.assign(promise, {
    abort: () => controller.abort(new Error('Request cancelled')),
  });
};
