// POD
export const MPS = {
  id: 'MPS',
  lang: {
    kr: true,
    en: true,
    jp: true,
  },
};

// 셀러 상품
export const PBG = {
  id: 'PBG',
  lang: {
    kr: true,
    en: true,
    jp: true,
  },
};

// 입고 상품
export const TPL = {
  id: 'TPL',
  lang: {
    kr: true,
    en: true,
    jp: true,
  },
};

// 디지털/전자책
export const DTG = {
  id: 'DTG',
  lang: {
    kr: true,
    en: false,
    jp: false,
  },
};

export const types = {
  MPS,
  PBG,
  TPL,
  DTG,
};
