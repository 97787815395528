const isBrowser = () =>
  typeof window !== 'undefined' && typeof document !== 'undefined' && globalThis === window;

export const isProd = () => {
  if (isBrowser()) {
    return globalThis.ENV.is_prod_real;
  } else {
    return globalThis.process.env.NODE_ENV === 'production';
  }
};

export const isStage = () => {
  if (isBrowser()) {
    return globalThis.ENV.is_stg;
  } else {
    return globalThis.process.env.NODE_ENV === 'staging';
  }
};

export const isDev = () => {
  return !isProd() && !isStage();
};

export const getDfHostByEnvironment = () => {
  if (isProd()) {
    return 'https://df.marpple.com';
  } else if (isStage()) {
    return 'https://df.tts.marpple.tw';
  } else {
    return 'http://localhost:9022';
  }
};
