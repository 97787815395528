import { filter, go, map, pluck } from 'fxjs/es';
import {
  ARCH_POSTCARD_M,
  ARCH_POSTCARD_S,
  CARD_TAG,
  MINI_SQUARE_FOLDING_CARD_HORIZON,
  MINI_SQUARE_FOLDING_CARD_VERTICAL,
  SINGLE_BOOKMARK,
  SQUARE_FOLDING_CARD_HORIZON,
  SQUARE_FOLDING_CARD_VERTICAL,
  STANDARD_FOLDING_CARD_HORIZON,
  STANDARD_FOLDING_CARD_VERTICAL,
  STANDARD_SLOGAN,
  WIDE_SLOGAN,
  POSTER_A3,
  POSTER_A4,
  SQUARE_POSTER,
  SKKU_NOTE,
  SPRING_NOTE_A4,
  SPRING_NOTE_A5,
  SPRING_NOTE_B5,
  SPRING_NOTE_A6,
} from './FirstEdition.js';

export const NEW_WE_BPS = [
  CARD_TAG,
  SINGLE_BOOKMARK,
  ARCH_POSTCARD_S,
  ARCH_POSTCARD_M,
  STANDARD_FOLDING_CARD_HORIZON,
  STANDARD_FOLDING_CARD_VERTICAL,
  SQUARE_FOLDING_CARD_HORIZON,
  SQUARE_FOLDING_CARD_VERTICAL,
  MINI_SQUARE_FOLDING_CARD_HORIZON,
  MINI_SQUARE_FOLDING_CARD_VERTICAL,
  WIDE_SLOGAN,
  STANDARD_SLOGAN,
  POSTER_A3,
  POSTER_A4,
  SQUARE_POSTER,
  SKKU_NOTE,
  SPRING_NOTE_A4,
  SPRING_NOTE_A5,
  SPRING_NOTE_B5,
  SPRING_NOTE_A6,
];
export const FOLDING_BPS = go(
  NEW_WE_BPS,
  filter((bp) => bp.folding_m_url),
);
export const WE_BP_IDS = [4577, 4578, 4602, 4579, 4580, 5757, 7957, 7958, 7959].concat(
  go(NEW_WE_BPS, pluck('bp_id')),
);
export const CONFIRM_BPS = go(
  NEW_WE_BPS,
  map((bp) => {
    return {
      base_product_id: bp.bp_id,
      pc: bp.confirm_pc_url,
      pc_en: bp.confirm_pc_url_en,
      pc_jp: bp.confirm_pc_url_jp,
      m: bp.confirm_m_url,
      m_en: bp.confirm_m_url_en,
      m_jp: bp.confirm_m_url_jp,
    };
  }),
);
export const AMBIGUOUS_BPS = go(
  NEW_WE_BPS,
  map((bp) => {
    return {
      base_product_id: bp.bp_id,
      pc: bp.ambiguous_pc_url,
      pc_en: bp.ambiguous_pc_url_en,
      pc_jp: bp.ambiguous_pc_url_jp,
      m: bp.ambiguous_m_url,
      m_en: bp.ambiguous_m_url_en,
      m_jp: bp.ambiguous_m_url_jp,
    };
  }),
);
// const postcard_ids = new Set([4577, 4578, 4602]);
// const note_ids = new Set([4579, 4580]);

export const isWeBaseProduct = (base_product_id) => {
  const bp_ids = [5940, 5945];
  /* WE_BP_IDS 팝업같은거 안뜨도록 하기 위해서 */
  return bp_ids.includes(base_product_id) || WE_BP_IDS.includes(base_product_id);
};
