import { ProductCardData } from '../../../../features/ProductList/type';
import { ProductTag } from '../ProductTag/ProductTag';
import { ProductCardHelper, SoldOutData } from '../ProductCard/ProductCardHelper';

type QuantityLimitedData = Pick<ProductCardData, 'quantity' | 'order_count' | 'is_quantity_public'> &
  SoldOutData;
type PeriodLimitedData = Pick<ProductCardData, 'sell_start_at' | 'sell_end_at'> & SoldOutData;

export class ProductBadgeHelper {
  static isBothQuantityAndPeriodLimitedBadgeVisible(data: QuantityLimitedData & PeriodLimitedData): boolean {
    return (
      ProductBadgeHelper.isQuantityLimitedBadgeVisible(data) &&
      ProductBadgeHelper.isPeriodLimitedBadgeVisible(data)
    );
  }

  static isQuantityLimitedBadgeVisible(data: QuantityLimitedData): boolean {
    const VISIBLE_RANGE = {
      MIN: 1,
      MAX: 499,
    } as const;
    const { is_quantity_public, quantity, order_count } = data;
    const stock = quantity - order_count;
    return (
      !ProductCardHelper.isSoldOut(data) &&
      stock >= VISIBLE_RANGE.MIN &&
      stock <= VISIBLE_RANGE.MAX &&
      !!is_quantity_public
    );
  }

  static isPeriodLimitedBadgeVisible(data: PeriodLimitedData) {
    // 기간 한정 아님
    if (!ProductCardHelper.isPeriodLimited(data)) {
      return false;
    }

    const diff = ProductTag.getDiffTime(data.sell_end_at);

    // 기간한정 상품 종료까지 96시간 이상 남아있으면 숨겨진다.
    if (diff.day >= 4) {
      return false;
    }

    return (
      !ProductCardHelper.isSoldOut(data) &&
      !ProductCardHelper.isBeforeSale(data) &&
      !ProductCardHelper.isEndedSale(data)
    );
  }

  /**
   * - 기간한정 뱃지의 우선순위를 구하는 함수
   * - **낮은 숫자**일수록 **우선** 노출된다.
   */
  static getPeriodLimitedBadgePriority(date: Date): number {
    const diff = ProductTag.getDiffTime(date);
    if (diff.day >= 4) return 8; // 96시간 이상
    if (diff.day >= 2) return 7; // 96시간 미만 ~ 48시간 이상
    if (diff.day >= 1) return 6; // 48시간 미만 ~ 24시간 이상
    if (diff.hour >= 12) return 5; // 24시간 미만 ~ 12시간 이상
    if (diff.hour >= 1) return 4; // 12시간 미만 ~ 1시간 이상
    if (diff.minute >= 10) return 3; // 1시간 미만 ~ 10분 이상
    if (diff.minute >= 1) return 2; // 10분 미만 ~ 1분 이상
    return 1; // 1분 미만
  }

  /**
   * - 수량한정 뱃지의 우선순위를 구하는 함수
   * - **낮은 숫자**일수록 **우선** 노출된다.
   */
  static getQuantityLimitedBadgePriority(quantity: number): number {
    if (quantity < 10) return 3;
    if (quantity < 50) return 4;
    if (quantity < 100) return 5;
    if (quantity < 300) return 6;
    if (quantity < 500) return 7;
    return 8;
  }
}
