import { commify } from './util.js';

export const makeCurrencyPrice = (price, lang) => {
  const commified = commify(price);
  if (lang === 'kr') return `${commified}원`;
  if (lang === 'en') return `$${commified}`;
  if (lang === 'jp') {
    if (G.collabo_type === '') return `${commified}円`;
    else return `¥${commified}`;
  }
};

export const getPriceCurrency = () => {
  if (T.lang === 'kr') return `원`;
  if (T.lang === 'en') return `$`;
  if (T.lang === 'jp') return `円`;
};

export const makeCommifiedCurrencyPrice = (price, lang) => {
  const commified = commify(price);
  if (lang === 'kr') return `${commified}원`;
  if (lang === 'en') return `$${commified}`;
  if (lang === 'jp') {
    if (G.collabo_type === '') return `${commified}円`;
    else return `¥${commified}`;
  }
};
