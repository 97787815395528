import { type Html } from 'rune-ts';
import { LiveIcon } from '../../../atoms/Icon';
import { ProductBadgeBase } from '../ProductBadgeBase';
import { IProductBadge } from '../interface';

export class RealtimeBadge extends ProductBadgeBase<Date> implements IProductBadge {
  setLabel(): void {
    const now = new Date();
    const diff = ProductBadgeBase.diffDate(now, this.data.value);

    if (diff.day > 0) {
      this.label = ET('mps2::product::badge::period_limited_day', diff);
    } else if (diff.hour > 0) {
      this.label = ET('mps2::product::badge::period_limited_hour', diff);
    } else if (diff.minute > 0) {
      this.label = ET('mps2::product::badge::period_limited_minute', diff);
    } else {
      this.label = ET('mps2::product::badge::period_limited_second', diff);
    }
  }

  isTimerNeeded(): boolean {
    return true;
  }

  getIcon(): Html | string | null {
    return LiveIcon;
  }
}
