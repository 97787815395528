import { go } from 'fxjs/es';
import { $el, $prependTo, $delegate, $remove, $appendTo, $removeClass, $closest, $addClass } from 'fxdom/es';
import { UtilS } from '../../../../Util/S/Function/module/UtilS.js';
import { MShopUtilTmplS } from '../../S/Tmpl/module/MShopUtilTmplS.js';
import { rune } from 'rune-ts';

const end_class = 'toast-message--end';
const toast_message_types = ['error', 'info', 'confirm', 'cart', 'like', 'image', 'store_like'];
let toast_container_el;
let position = 'INITIAL-POSITION';

const checkToastMessageType = (type) => {
  if (!toast_message_types.includes(type)) throw new Error('토스트 메세지 타입이 올바르지 않습니다.');
};

const removeToastMessage = (message_el) => {
  $remove(message_el);
  $removeClass(end_class, message_el);
  if (!toast_container_el.children.length) {
    $remove(toast_container_el);
  }
};

const initToastContainer = (opt) =>
  go(
    $el(MShopUtilTmplS.createToastContainer(opt)),
    $delegate('animationend', '.toast-message', (e) => {
      if (e.animationName !== 'toastOut') return;
      removeToastMessage(e.currentTarget);
    }),
    $delegate('click', '.toast-message__close', (e) => {
      removeToastMessage($closest('.toast-message', e.target));
    }),
  );

export const createToastMsg = (type, inner) => {
  checkToastMessageType(type);

  return $el(
    UtilS.isNessApp()
      ? MShopUtilTmplS.createNessToastItem(inner, { type })
      : MShopUtilTmplS.createToastItem(inner, { type }),
  );
};

export const popToastMsg = (input_el, type, opt = {}) => {
  if (UtilS.isNessApp()) {
    return popToastMsgNess(input_el, type);
  }

  if (!toast_container_el || position !== opt?.position) {
    const layout_element =
      document.querySelector('.LayoutView') || document.querySelector('.MarppleShopStudioLayoutView');
    if (!layout_element) return;

    const layout_options = rune.getUnknownView(layout_element).state;

    toast_container_el = initToastContainer({ ...opt, ...layout_options });
    position = opt?.position;
  }
  if (!toast_container_el.parentNode) $appendTo(document.body, toast_container_el);
  const item_el = input_el instanceof HTMLElement ? input_el : createToastMsg(type, input_el);

  $prependTo(toast_container_el, item_el);
  setTimeout(() => {
    $addClass(end_class, item_el);
  }, 2500);
};

const popToastMsgNess = (input_el, type) => {
  if (!toast_container_el) toast_container_el = initToastContainer();
  if (!toast_container_el.parentNode) $appendTo(document.body, toast_container_el);

  const item_el = input_el instanceof HTMLElement ? input_el : createToastMsg(type, input_el);

  $prependTo(toast_container_el, item_el);
  setTimeout(() => {
    $addClass(end_class, item_el);
  }, 2500);
};
